// bibliotek
import React, {useState} from "react";
import Marquee from "react-fast-marquee";
import axios from "axios";
// style
import "./footer.scss"

// images
import footer_bg from "../../assets/footer/1.jpg"
import socialIcon_1 from "../../assets/icon/behance.svg";
import socialIcon_2 from "../../assets/icon/facebook.svg";
import socialIcon_3 from "../../assets/icon/gmail.svg";
import socialIcon_4 from "../../assets/icon/instagram.svg";
import socialIcon_5 from "../../assets/icon/pinterest.svg";
import socialIcon_6 from "../../assets/icon/vkontakte.svg";
import chary_1 from "../../assets/footer/bals1.png";
import chary_2 from "../../assets/footer/bals2.png";
import chary_3 from "../../assets/footer/bals5.png";


function Footer(){

    const [messageName, setMessageName] = useState("");
    const [messageAdress, setMessageAdress] = useState("");
    const [messageText, setMessageText] = useState("");
  
   const urlSend = `Заявка с сайта! \nИмя: ${messageName},\nАдрес: ${messageAdress}, \nТекст: ${messageText}`
   
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.post("https://api.telegram.org/bot6451764671:AAGizWg_3drCik5FseZI7JG4mCaECV-fRsA/sendMessage", {
          chat_id: "-1001867028495",
          text: urlSend,
      });
        alert("Уведомление успешно отправлено!");
        setMessageName("");
      } catch (error) {
        console.error(error);
        alert("Ошибка при отправке уведомления!");
      }
    };

    return (
      <div className="footer">
        <div className="footer__wrapper">
          <div className="footer__container">
            <img className="footer__img_bg1" src={chary_1} alt="" />
            <img className="footer__img_bg2" src={chary_2} alt="" />
            <img className="footer__img_bg3" src={chary_3} alt="" />
            <img className="footer__img_bg4" src={chary_3} alt="" />

            <div className="footer__logo">
              <Marquee speed={90}>
                <h4>contacts</h4>
                <h5>&nbsp;&nbsp;</h5>
                <h4>contacts</h4>
                <h5>&nbsp;&nbsp;</h5>
              </Marquee>
            </div>
            <div className="footer__contact">
              <h4>Let's talk</h4>
              <div className="footer__contact__cosial">
                <img src={socialIcon_1} alt="" />
                <img src={socialIcon_2} alt="" />
                <img src={socialIcon_3} alt="" />
                <img src={socialIcon_4} alt="" />
                <img src={socialIcon_5} alt="" />
                <img src={socialIcon_6} alt="" />
              </div>
              <form onSubmit={handleSubmit}>
                <h6>Your Name</h6>
                <input
                  autoComplete="off"
                  name="lastName"
                  type="text"
                  required
                  value={messageName}
                  onChange={(e) => setMessageName(e.target.value)}
                />
                <h6>Your Email</h6>
                <input
                  autoComplete="off"
                  name="email"
                  type="email"
                  required
                  value={messageAdress}
                  onChange={(e) => setMessageAdress(e.target.value)}
                />
                <h6>Your Message</h6>
                <textarea
                  autoComplete="off"
                  name="text"
                  cols="50"
                  rows="2"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                ></textarea>
                <button type="submit">Sign in</button>
              </form>
            </div>
          </div>
          <div className="footer__images">
            <img src={footer_bg} alt="" />
          </div>
        </div>
      </div>
    );
}

export default Footer;